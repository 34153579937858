
.footer {
   display: flex;
   flex-direction: column;
   margin-bottom: 20px;
   font-weight: bold;
   position: fixed;
   bottom: 0;
   right: 0;
   left: 0;
 }

 .footer-trademark {
   display: flex;
   flex-direction: row;
   justify-content: center;
   font-size: small;   
   font-weight: 100;
 }

 .footer-created {
   display: flex;
   flex-direction: row;
   justify-content: center;
   font-size: x-small;   
   font-weight: 100;
 }

 .footer-info  {
   display: flex;
   justify-content: center;
 }
 
 .footer-info-left, .footer-info-center, .footer-info-right {
   font-size: small;
   display: flex;
   flex-direction: column;
   width: 30%;
 }

 .footer-date {
   display: flex;
   flex-direction: row;
   align-items: center;
 }

 .footer-seperator {
   flex-grow: 1;
   border: none;
   height: 1px;
   background-color: black;
   width: 100%;
 }

 .footer-info__date, .footer-info__privacy, .footer-info__TOU {
   margin-bottom: 5px;
 }

 .footer-info__date {
   text-align: start;
 }

 .footer-info__TOU {
   text-align: end;
 }
 
 .amwellBug {
  width: 150px;
  display: flex;
  float: left;
  top: 10px;
  left: 100px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 35px;
}

.LogInOut {
  position: calc(100vh/2);
  margin: 0px;
  width: 150px;
  height: auto;
  color: white;
}

.LogInOut:hover {
  color: #e0e9f5;
}

.Header {
  width: 100%;
  height: 65px;
  background-color: #044599;
}


