.PortalExp-Org_Title_Icon {
  width: 10%;
  display: flex;
  margin-left: 10px;
  justify-content: left;
}

.PortalExp-Tiles {
    display: inline-flex;
    border-radius: .5vw;
    background: white;
    flex-direction: column;
    margin: 10px;
    width: 650px;
  }
  
  .PortalExp-Tile_Container {
    margin-bottom: 10px;
  }

  .PortalExp-TH_Section  {
    display: flex;
  }
  
  .PortalExp-Title_Section {
    padding-top: 20px;
  }

  .PortalExp-Title_Text {
    width: 100%;
    font-size:xx-large;
    flex-direction: column;
    text-decoration: underline;
  }

  .PortalExp-Seperator {
    flex-grow: 1;
    border: none;
    height: 1px;
    background-color: black;
    width: 100%;
  }

  .PortalExp-Section_Left, .Section_Right {
    flex-direction: column;
    margin: 5px;
    width: 15%;
  }

  .PortalExp-Icon {
    display: flex;
    /*align-content: start;*/
  }

  .PortalExp-Main_Text {
    flex-direction: row;
    text-align: start;
  }

  .PortalExp-Sub_Text {
    text-align:left;
    margin-left: 15px;
    color: darkcyan;
    font-size: large;
  }

  .PortalExp-THMenu_Section {
    position: absolute;
    background-image: linear-gradient(to bottom, #1370ea, #0f438a);
    top: 2.5vh;
    left: 0;
    right: 0;
    width: 35vw;
    height: 95%;
    align-items: center;
    margin: 0 auto;
    padding: 50;
    min-height: 10vh;
    text-align: center;
    background-size: cover;
    border-radius: 2vw;
  }

  .PortalExp-THMenu_Banner {
    font-size:xx-large;
    font-weight: 300;
    color: white;
    background-color: #1370ea;
    margin: auto;
  }

  .PortalExp-THOption_Seperator {
    flex-grow: 1;
    border: none;
    height: 1px;
    margin: 0%;
    background-color: black;
    width: 100%;
  }

  .PortalExp-THMenu_Icon {
    width: 100%;
    display: flex;
    border-top-right-radius: 2vw;
    border-top-left-radius: 2vw;
  }
  .PortalExp-THMenuOption_Icon {
    padding-top: 10px;
    padding-left: 10px;
    float: left;
  }
  
  .PortalExp-THMenu_Option_Section {
    width: 100%;
    display: inline-block;
    text-align:right;
    color: white;
    font-size:xx-large;
    font-weight: 300;
    background-image: linear-gradient(to bottom, #327acf, #0f438a);
  }

  .PortalExp-THMenu_Option_Text {
    width: 100%;
    height: 70px;
    display: inline-block;
    text-align:right;
    padding-right: 35px;
    padding-top: 10px;
    color: white;
    font-size:xx-large;
    font-weight: 300;
    
  }

  .PortalExp-THMenu_Option_Link {
    width: 100%;
    height: 70px;
    text-align:right;
    text-decoration: none;
    padding-top: 10px;
    color: white;
    font-size:xx-large;
    font-weight: 300;
  }

  .PortalExp-THMenu_Option_Link:hover {
    color:darkblue;
    text-decoration: underline;
  }

  .PortalExp-THUCMenu_Section {
    position: absolute;
    background-color:lightgrey;
    top: 2.5vh;
    left: 0;
    right: 0;
    width: 90vw;
    height: 95%;
    align-items: center;
    margin: 0 auto;
    padding: 50;
    min-height: 10vh;
    text-align: center;
    background-size: cover;
    border-radius: 2vw;
  }

  .PortalExp-UCTitle_Section {
    height: 70px;
    padding: 15px;
    text-align:center;
    font-size:xx-large;
    font-weight: 300;
    background-color: #1370ea;
    color: white;
    border-top-right-radius: 2vw;
    border-top-left-radius: 2vw;
  }

  .PortalExp-UCVisitFrame {
    width: 100%;
    height: 100%;
  }