body {
    margin: 0;
    background-color: black;
}

main {
    margin: 0%;
    padding: 0;
    min-height: 100vh;
    text-align: center;
    background: black url("/src/images/amwl-background-blurred.png") no-repeat center top;
    background-size: cover;
    width: 100vw;
    height: 100%;
}

h1 {
    color:black
}