.container {
  display: inline-block;
  align-items: center;
}

.header {
  width: 100%;
  height: 5rem;
  background-color: #044599;
  padding: 0 5%;
}

.header nav {
  height: 100%;
}

.header ul {
  height: 100%;
  text-align: center;
  width: 75%;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 2px;
  align-items: center;
  justify-content: center;
}

.header li {
  display: flex;
  padding: 5px 5px;
}

.header a {
  color: white;
  text-decoration: none;
  width:150px;
  padding:5px 5px
}

.header a:hover,
.header a:active,
.header a.active {
  color:cornflowerblue;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #95bcf0;
}