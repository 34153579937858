@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0%;
}

html {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

body {
  margin: 0;
  background-color: #e0e9f5;
}

main {
  margin: 0%;
  padding: 0;
  min-height: 100vh;
  text-align: center;
}

h1,
h2,
h3,
p {
  color: #042b5f;
}

.AmEx__Provider-Section {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto ;
  padding: 50;
  min-height: 57vh;
  background: black url("/src/images/Landing_Bkgd.jpeg") no-repeat center top;
  background-size:cover;
  width: 50vw;
  border-radius: 3vw;
}

.AmEx__Patient-Section {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto ;
  padding: 50;
  min-height: 40vh;
  background: black url("/src/images/Landing_Bkgd.jpeg") no-repeat center top;
  background-size:cover;
  width: 50vw;
  border-radius: 3vw;
}

.AmEx__Portal-Section {
  position: absolute;
  background-color:lightgrey;
  top: 2.5vh;
  left: 0;
  right: 0;
  width: 95vw;
  height: 95%;
  align-items: center;
  margin: 0 auto;
  padding: 50;
  min-height: 10vh;
  text-align: center;
  background-size: cover;
  border-radius: 2vw;
}

.AmEx__Portal-h3 {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AmEx__Portal-Btn {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 2%;
  margin: 0 auto;
  display: inline-block;
  align-items: center;
  background-color: #044599 ;
  color: white;
  width: 125px;
  border-radius: 2.5vw;
}

.AmEx__Portal-Banner {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  background-color: #044599;
  border-top-right-radius: 2vw;
  border-top-left-radius: 2vw;
}

.AmEx__NA-Section {
  position: absolute;
  top: 25vh;
  left: 0;
  right: 0;
  width: 60vw;
  height: 35%;
  align-items: center;
  margin: 0 auto;
  padding: 50;
  min-height: 20vh;
  text-align: center;
  background: black url("/src/images/Landing_Bkgd.jpeg") no-repeat center top;
  background-size: cover;
  border-radius: 2vw;
}

.AmEx__NA-h3 {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AmEx__NA-Btn {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 2%;
  margin: 0 auto;
  display: inline-block;
  align-items: center;
  background-color: #044599 ;
  color: white;
  width: 125px;
  border-radius: 2.5vw;
}

.experienceInstructions {
  padding-top: 2vh;
}

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}

.configFormLabel {
  font-weight: bold;
  padding: 2.5px;
  margin-left: 5px;
}

.configFormGroup {
  display: inline-block;
  width: 200px;
  margin-left: 5px;
  text-align: center;
}

.configFormButton {
  cursor: pointer;
  text-decoration: underline;
}

.experienceButton {
  cursor: pointer;
  display: inline-block;
  width: 175px;
  height: 50px;
  background:#044599;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 25px;
  border-radius: 2.5vw;
  color: #e0e9f5;
}

.experienceButton:hover {
  background-color: blue;
  color: #e0e9f5;
}

.experienceButton:active {
  background-color:cornflowerblue;
}

.button {
  cursor: pointer;
  display: inline-block;
  width: 225px;
  height: 50px;
  background:#044599;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  color: white;
  line-height: 25px;
  border-radius: 2.5vw;
}

.button:hover {
  background-color: blue;
  color: #e0e9f5;
}

.button:active {
  background-color:cornflowerblue;
}

.SvButton {
  cursor: pointer;
  display: inline-block;
  width: 225px;
  height: 50px;
  background:#044599;
  padding: 10px;
  margin-top: 10px;
  margin-left: 50px;
  text-align: center;
  color: white;
  line-height: 25px;
  border-radius: 2.5vw;
}

.SvButton:hover {
  background-color: blue;
  color: #e0e9f5;
}

.SvButton:active {
  background-color:cornflowerblue;
}

.copyIDButton {
  width: 3.25em;
  display:inline;
  align-items: center;
  padding: 0 10px 0 0 0;
  height: 100%;
  text-decoration: underline;
  background: #044599;
  border: none;
  outline: none;
  border-radius: 2.5vw;
  overflow: hidden;
  cursor: pointer;
  margin-Right: 5px;
}

.copyIDButton:hover {
  background-color: blue;
}

.copyIDButton:active {
  background-color:cornflowerblue;
}

.copyIDButton__icon {
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  color: white;
  height: 100%;
  text-decoration: underline;
  font-size: 1.5em;
  background-color: rgba(0, 0, 0, 0, 0.08);
  width: 1.75em; 
  height: 100%;
}

.copyButton {
  width: 3.25em;
  height: 2.25em;
  padding: 0;
  background: #044599;
  border: none;
  outline: none;
  border-radius: 2.5vw;
  overflow: hidden;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 10px;
}

.copyButton:hover {
  background-color: blue;
}

.copyButton:active {
  background-color:cornflowerblue;
}

.copyButton__icon {
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  color: white;
  height: 100%;
  text-decoration: underline;
  font-size: 1.5em;
  background-color: rgba(0, 0, 0, 0, 0.08);
  width: 1.75em; 
  height: 100%;
}

.homeTitle {
  font-size: 2em;
  text-align: center;
  padding: 0;
  margin: 0px;
  width: 100%;
  display: inline-block;
}

.defaultVisitIdValue {
  background-color: lightgray;
  text-align: center;
}

.encounterRandomPrefix {
  margin-top: 10px;
}

.encounterRandomPrefixInput {
  margin-left: 10px;
}

.durationTitle {
  margin-top: 10px;
  font-size: medium;
}

.dropdown {
  width: 200px;
  margin: .5vh auto;
  position: relative;
  font-size: medium;
}

.TenantSpan {
  float: right;
  margin: 0px;
  width: 350px;
  height: auto;
  display: flex;
  margin-right: 20px;
}

.TenantID {
  margin: 0px;
  width: 150px;
  height: auto;
  display: flex;
}

.EhrID {
  margin: 0px;
  width: 150px;
  height: auto;
  display: flex;
}

/*
.dropdown .dropdown-btn {
  padding: 15px 20px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown .dropdown-content {
  position: absolute;
  top: 110%;
  left: 0;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: #333;
  width: 95%;
}

.dropdown .dropdown-content .drop-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}

.dropdown .dropdown-content .drop-item:hover {
  background: #f4f4f4;
}
*/


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}